var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("rule", {
        ref: "guide",
        attrs: {
          "is-preview": "",
          "is-mask-closable": "",
          "hide-edit-btn": "",
          "props-filters": { typeId: 0 },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }